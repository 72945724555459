.nav {
  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: $nav-border-color;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &.-sub {
    &::after {
      background-color: $nav-sub-border-color;
    }
  }
}

.nav__list {
  list-style: none;
  margin: 0 0 0 20px;
  padding: 0;
  @include clearfix;
}

.nav__item {
  float: left;
  position: relative;
  text-transform: uppercase;
  color: $nav-text-color;
  font-family: $font-condensed;
  letter-spacing: 0.04em;
  z-index: 2;
  &:last-child:not(.-has-child) {
    padding-right: 0;
  }
  &.-has-child {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAFCAYAAACJmvbYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkIwOEMyQkYzODE3QzExRTZBMzY4RjVGNDg5M0ZCNUZFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkIwOEMyQkY0ODE3QzExRTZBMzY4RjVGNDg5M0ZCNUZFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QjA4QzJCRjE4MTdDMTFFNkEzNjhGNUY0ODkzRkI1RkUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QjA4QzJCRjI4MTdDMTFFNkEzNjhGNUY0ODkzRkI1RkUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7nOr3zAAAAVUlEQVR42mL8//8/KwMDAwh/Y0AALiD+wwQkdIA4HioAApxAHAfEuiDJ80B8G4hjgJgfSt8D4rOMQGNhRnkCsTEQnwPibWARkCQUgxTqQWmwGECAAQBzUiRnyInkZwAAAABJRU5ErkJggg==') no-repeat right center;
  }
}

.nav__item > a,
.nav__item > span {
  color: inherit;
  font-family: inherit;
  display: block;
  padding: 0 15px;
  line-height: 46px;
}

.nav__item.-has-child > a {
  padding-right: 30px;
}

.nav__close {
  margin-left: 17px;
  font-size: 18px;
  margin-top: 2px;
}