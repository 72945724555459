.full-slider {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  .flex-viewport,
  .slides {
    min-height: 100%;
    height: 100%;
    width: 100%;
    min-width: 100%;
  }
  .slides {
    margin: 0;
    padding: 0;
    > li {
      height: 100%;
    }
  }
}

.full-slider__item {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
  position: relative;
}

.full-slider__title {
  position: absolute;
  right: $content-space;
  bottom: $content-space + 35px;
  font-family: $font-condensed;
  font-size: 24px;
}