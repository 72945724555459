.icon-nav {
}

.icon-nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.icon-nav__item {
  box-shadow: 0 -1px 5px 1px rgba(0, 0, 0, 0.15);
}

.icon-nav__link {
  display: block;
  text-align: center;
  line-height: 1;
  &:hover {
    line-height: 1.4;

    &::before {
      top: 0;
      transform: translateY(0);
    }
    .icon-nav__text {
      display: block;
    }
  }
}

.icon-nav__text {
  font-size: 9px;
  font-family: $font-condensed;
  letter-spacing: 0.04em;
  display: none;
}