.sidebar--with-image {
  background: no-repeat left top transparent;
  background-size: cover;
  position: relative;
  color: $white;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: transparentize($black, 0.3);
  }
}

.sidebar__content {
  z-index: 1;
  position: relative;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}