//Thin
@font-face {
  font-family: 'Bebas';
  src: url('#{$font-path}/bebas/bebasneue_thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

//Light
@font-face {
  font-family: 'Bebas';
  src: url('#{$font-path}/bebas/bebasneue_light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

//Regular
@font-face {
  font-family: 'Bebas';
  src: url('#{$font-path}/bebas/bebasneue_regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

//Bold
@font-face {
  font-family: 'Bebas';
  src: url('#{$font-path}/bebas/bebasneue_bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}