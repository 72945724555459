.range-slider {
  @include responsive-font(0.8vw, 13px, 16px);
  display: flex;

  .no-js & {
    display: none;
  }
}

.range-slider__input {
  display: inline;
  background-color: transparent;
  border-width: 0;
  @include responsive-font(0.8vw, 13px, 15px);
  font-weight: 500;
  cursor: default;
  padding: 0 6px;
  height: 19px;
  width: 46px;
  width: 6ch;
  margin-bottom: 0;
  //override zurb
  &:disabled {
    background-color: transparent;
    cursor: default;
  }
}