.contacts {
  padding-left: $content-space;
  max-width: $sidebar-width;
}

.contacts__title {
  font-family: $font-condensed;
  font-size: 30px;
  font-weight: 400;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid $medium-gray;
}

.contacts__address {
  font-family: $font-condensed;
  margin-bottom: 30px;
}

.contacts__email {
  font-family: $font-condensed;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $medium-gray;
}

.contacts-call__item {
  font-family: $font-condensed;
  margin-bottom: 10px;
}

.contacts-call__title {
  font-family: $font-condensed;
  font-size: 16px;
  margin-bottom: 0;
}

.contacts-call__phone {
  font-family: $font-condensed;
  color: $medium-gray;
  > a {
    color: inherit;
  }
}