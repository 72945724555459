.motto {
  text-transform: uppercase;
  color: $white;
  &.-over-content {
    z-index: 100;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: $content-space;
    margin-right: $content-space;
    pointer-events: none;
  }

}

.motto__title {
  font-weight: 500;
  font-size: 56px;
}

.motto__sub {
  font-weight: 300;
  font-size: 35px;
  display: block;
  position: relative;
  &:before {
    content: '';
    height: 3px;
    width: 26px;
    position: absolute;
    background-color: $white;
    top: -6px;
  }
}