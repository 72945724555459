.checkbox-custom {
  position: relative;
  cursor: pointer;
}

.checkbox-custom__checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.checkbox-custom__label {
  border: 1px solid $light-gray;
  width: 35px;
  height: 35px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 35px;
}

//override zurb

.checkbox-custom__checkbox + .checkbox-custom__label {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 7px;
}

//checked state

.checkbox-custom__checkbox {
  &:checked + .checkbox-custom__label {
    background-color: $light-gray;
    border-color: $black;
  }
}

