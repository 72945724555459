html, body {
  height: 100vh;
  overflow: hidden;
  min-height: 700px;
  min-width: 1200px;
}

.site-wrap {
  position: relative;
  height: 100%;
  display: flex;
}