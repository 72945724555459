@mixin button-base() {
  @include disable-mouse-outline;
  font-family: $font-condensed;
  display: inline-block;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: $button-radius;
  padding: $button-padding;
  margin: 0;
  font-size: map-get($button-sizes, default);
  letter-spacing: 0.05em;
}

@mixin button-style($background, $text: $white, $transparency: 0) {

  color: $text;
  background-color: transparentize($background, $transparency);
  &:hover,
  &:active,
  &:focus {
    background-color: transparentize($background, $transparency - 0.1);
  }
}

@mixin button-hollow-style($background, $text, $text-hover: $white, $transparency: 0) {
  font-family: $font-condensed;
  color: $text;
  border: 1px solid transparentize($background, $transparency);
  &:hover,
  &:active,
  &:focus {
    background-color: transparentize($background, $transparency);
    color: $text-hover;
  }
}