.order-block {
  margin-bottom: $content-space;
}

.order-block--inline {
  font-size: 0;
  .order-block__btn {
    width: 50%;
    display: inline-block;
    padding-top: $content-space + 10;
    padding-bottom: $content-space + 10;
    border-left: 0;
    border-right-width: 0;
    &:not(:last-child) {
      border-right-width: 1px;
    }
  }
}

.order-block__btn {
  font-size: 11px;
  text-transform: uppercase;
  border: 1px solid transparentize($white, 0.6);
  padding-left: 50px;
  padding-right: $content-space;
  line-height: 1;
  text-align: left;
  background: no-repeat 18px center;
}

.order-block__btn--phone {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjhBQzRDQTgzQUZDQzExRTZCQTExRUU4QzBGOUI3RkM0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjhBQzRDQTg0QUZDQzExRTZCQTExRUU4QzBGOUI3RkM0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OEFBNTVCMzZBRkNDMTFFNkJBMTFFRThDMEY5QjdGQzQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OEFDNENBODJBRkNDMTFFNkJBMTFFRThDMEY5QjdGQzQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5urhRBAAACHElEQVR42uyVQUiUQRTH3dLq1kGN0It4CPamfSt1KRXTS0HUiqAIItJJIT2oN1NPggcV6hQRgRBEJrnrRdFSLwa71s1LiCCC5HoQOlSa2+/Bf2n8/Fz04M0HP2bmzZv35pv3Zr5QOp3OOUsJnQc4dYBIJHKsrW8cuLNEInFofCFL8CvQDJOQggMfKc01yzZQggLkQQ9sQD98hya4pq8Iqd+kuX7Z9mjt8QE4Ho8mCY3QBjegF4q0259iUrpe2bRpTVI+jgZgooFmHt5ABUxpRzEYgjjcE3HpYrKZ0hpbOy9f/5OMwqK/gIew5GzgOdRANWz5vv46fII56HD0d+AjtJPwtyHP88oYLEIdimWniophHW7BinQFalNqb8IXKIHNTBXh4zbdGbhrRzQKw+bct8NaWHWc35eTTfVzNLcqW7dUzdew+bYAlUqaX6xS1pxxFC6JqKNfk61fzGelBViARwEGP6DUGU/AHzHh6Etl6xfzuWABOqFb5+bKLIR1zibTykux+pkchGXrlrv56jbfZ19FmbdItfsSBmEM9uEyfABPxfBZTqr05XYpH8NvyIWn0AdPcP7uyGOnW/ga/sIzXSiTFt3WMo2/wStdLJMHMAAXoRXnyWyvqd3MLp3hrqrBzvgrbMusEMpVnpbMqyrLEZzvnfS5theyXiVp55rvy8GO8mUV9R5+BT3XuVmea1swLk78Pzj/J59a/gkwAFBXxZPcVMXaAAAAAElFTkSuQmCC');
  &.-light {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjU0MTVENzVBOEExODExRTY5OTMwRkUyRjI5REU4NTZGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjU0MTVENzVCOEExODExRTY5OTMwRkUyRjI5REU4NTZGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NTQxNUQ3NTg4QTE4MTFFNjk5MzBGRTJGMjlERTg1NkYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NTQxNUQ3NTk4QTE4MTFFNjk5MzBGRTJGMjlERTg1NkYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz46AmypAAAAp0lEQVR42mL4//8/AxbsAcRP/hMHnkDVYzOHgRFEYAFPgHg2EC9kIAzigTgViGWwyuKwGQQUiDAcpFYBqh6rWSx49K4AKvhBhB0c+CTxWbAGiN8QYYEIEJvj8yJNg4iJgcZg1IJRC0YtGLVgMFiAr7gOAZaGxBbX5FkAxBRXOETXB0A+CxAvA9HUqDKfgipzoAL0Sv8IqHJHayjEQ9WT5AOqNVsAAgwA5ApZUiUCjeQAAAAASUVORK5CYII=');
  }

}

.order-block__btn--scan {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjhBQTU1QjJDQUZDQzExRTZCQTExRUU4QzBGOUI3RkM0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjhBQTU1QjJEQUZDQzExRTZCQTExRUU4QzBGOUI3RkM0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OEE4OTk1NkRBRkNDMTFFNkJBMTFFRThDMEY5QjdGQzQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OEE4OTk1NkVBRkNDMTFFNkJBMTFFRThDMEY5QjdGQzQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4JpvwYAAAA2ElEQVR42mL8//8/AzowMTFhBVKbgdgFiJkZ8IO/QLwHiH3PnDnzG12SBYcmHSCWBGJ9IP5KwAJuIF4O1XOeWAv4gHgT0EVXGYgAQB/vg+phINYCEIgFarRlIA6oAPF6Ui24AMQbiLQgCpcEXguAQbSAyCAywCXHxEBjMGrBqAWjFoxaMBgswFeaGgBLyQQizdEipz4AFcECJFQ4JPtgMbA+qEUr96NBNFB8KZr4BFIt+ARqJQA1rkCr9N9ADVRAq/SdgHghKRZcAeIXQHyRhGbLFWySAAEGAInqMX+i9XU5AAAAAElFTkSuQmCC');
  &.-light {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjBFMjE1QjdCODk2MDExRTZBQzQ1ODgyQkZBMDQwRTlFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjBFMjE1QjdDODk2MDExRTZBQzQ1ODgyQkZBMDQwRTlFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MEUyMTVCNzk4OTYwMTFFNkFDNDU4ODJCRkEwNDBFOUUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MEUyMTVCN0E4OTYwMTFFNkFDNDU4ODJCRkEwNDBFOUUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4holzVAAABaElEQVR42uzVsUsCURzA8S6DQAlaInA6oUVwaLOhRclQIQe7vbX/oNmtw/8iR60/IKSWhsZwqSHoHEPKaCqX6/vkd/F4PcUCb8kHH+7e737v9zu5e6cThuHSvIezaPKbsTLu5DgTE7iJHQ517CKLdbn0hnvc4IIat1N/idmEmAoc4gRbuEQXdxhI2ga2sYd9PMLHOfVCs6A5z6CLPo6RkngZbTwIdV6WaynJ7cvazMQmnJfwglMkJZbAGd7RxIFoSkxdS0huUtaqGqUfTTjW8IqKcRM+nuBanpcr13wjXpFate8myGGIopGcxgh5LbamaPO85KSNtUWpmYuaXKFhudMj9LR5FR+iqsV7KteyvqFqL8u8gLbl7dtEoM09rApPiweSaw5VsxA1uTYWReMZ+rPo4FN0tLgruebwxrVjeyaxvF0z7JPWlH3SmnmfWHZ88IcdH9h2fCzfLmuTuXyFF/+M/6/JlwADAIDHOpqun9wAAAAAAElFTkSuQmCC');
  }
}

.order-block--details {
  .order-block__btn {
    padding: 20px 35px 20px 60px;
    margin: 15px 0;
    border-color: $dark-gray;
    color: $dark-gray;
    text-transform: none;
    font-size: 16px;
  }
}