.flexslider {
  border-width: 0;
  margin: 0;
}

.flex-direction-nav {
  position: absolute;
  top: 90px;
  left: ($content-space + 4);
  .flex-nav-prev,
  .flex-nav-next {
    display: inline-block;
    vertical-align: middle;
  }
  .flex-next {
    right: auto;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAOCAYAAAD9lDaoAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjdBNDVGMzM2ODU1QTExRTZBMjE1OEY1RDI4ODE1ODQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjdBNDVGMzM3ODU1QTExRTZBMjE1OEY1RDI4ODE1ODQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6N0EzMTBCNjE4NTVBMTFFNkEyMTU4RjVEMjg4MTU4NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6N0EzMTBCNjI4NTVBMTFFNkEyMTU4RjVEMjg4MTU4NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7Y7bfYAAAAjklEQVR42mL4//8/AxCvAmIXKBsDwxg2QPwSiH3wKQJhUyB+DsSh+BSBsB4QPwXiWHyKQFgDiB8BcQY+RSCsCMR3gbgIxGdhwA7uA7E9EO8BYn4mBtyACYr/4LJOGYjvA3EeLjdpQh2eisvhhkD8DIijcQWBORC/AOIgXIFpB40WT3zRsgyIHXFFMECAAQCJ31X3Z7VkjAAAAABJRU5ErkJggg==') no-repeat right center;
    &::before {
      display: none;
    }
  }
  .flex-prev {
    left: auto;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAOCAYAAAD9lDaoAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjdBMzEwQjVCODU1QTExRTZBMjE1OEY1RDI4ODE1ODQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjdBMzEwQjVDODU1QTExRTZBMjE1OEY1RDI4ODE1ODQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6N0EzMTBCNTk4NTVBMTFFNkEyMTU4RjVEMjg4MTU4NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6N0EzMTBCNUE4NTVBMTFFNkEyMTU4RjVEMjg4MTU4NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5u9IKMAAAAlklEQVR42mL4//8/Aw7sDMSrQGxcCryB+AUQ2+BSFArEz4HYBCaGriAWiJ8CsR6yOLKCdCB+BMQa6KbDGIVAfA+IFbG5EUTUA/ENIJbF5VMmBgaGv0AMohkZcAGo6hwgvg/Eyrisg+EUIH4MxJr4FIFwFBA/A2JDfIpAOBAa2ub4FIGwJxC/BGI7fIpA2BGIV4DYAAEGAFrRcFw36udXAAAAAElFTkSuQmCC') no-repeat left center;
    &::before {
      display: none;
    }
  }
  a {
    opacity: 0.5;
    width: 9px;
    height: 14px;
    font-size: 0;
    color: transparent;
    margin: 0 4px;
    position: static;
    top: auto;
    z-index: 10;
    overflow: visible;
    &:hover {
      opacity: 1;
    }
  }
}

.flex-control-nav {
  width: auto;
  position: absolute;
  bottom: auto;
  text-align: center;
  top: 135px;
  left: $content-space;
  counter-reset: slide-count;
  color: $white;
  font-size: 19px;
  line-height: 1.2;
  &::after {
    content: '/'counter(slide-count);
    font-size:19px;
  }
  li {
    counter-increment: slide-count;
    margin: 0;
    a {
      display: none;
      width: auto;
      height: auto;
      background: transparent;
      cursor: pointer;
      text-indent: 0;
      box-shadow: none;
      border-radius: 0;
      &.flex-active {
        background: transparent;
        display: block;
      }
    }
  }
}

.flexsider--right-side {
  .flex-direction-nav {
    bottom: $content-space;
    right: $content-space;
    top: auto;
    left: auto;
  }
  .flex-control-nav {
    bottom: $content-space;
    right: $content-space + 50;
    top: auto;
    left: auto;
  }
}

.flexsider--dark {
  .flex-control-nav {
    color: $black;
    a {
      color: $black;
    }
  }
  .flex-direction-nav {
    .flex-next {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAANCAYAAACUwi84AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkI5QTIzNzI2ODZGMjExRTY5NzhFRDJBMjEyNzQ5MUJFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkI5QTIzNzI3ODZGMjExRTY5NzhFRDJBMjEyNzQ5MUJFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QjlBMjM3MjQ4NkYyMTFFNjk3OEVEMkEyMTI3NDkxQkUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QjlBMjM3MjU4NkYyMTFFNjk3OEVEMkEyMTI3NDkxQkUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7XNyD5AAAAb0lEQVR42mJgYGDQAeJtQMzDgAUwAfE1IH4MxDuAmJ8BB2AE4klAfBqIhRjwgC4gPg/EovgUNQPxVSCWwKeoEohvALEcIx5FW3D5DGbCTSCWx+UGkNclSfIFLBzOYAsHUGjOBOJjuEJSF19cAAQYAMd1EhCyNcYaAAAAAElFTkSuQmCC');
    }
    .flex-prev {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAANCAYAAACUwi84AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkFCQjhDMzUwODZGMjExRTY4RkFDQkYxRkFGOEM4NDlDIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkFCQjhDMzUxODZGMjExRTY4RkFDQkYxRkFGOEM4NDlDIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QUJCOEMzNEU4NkYyMTFFNjhGQUNCRjFGQUY4Qzg0OUMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QUJCOEMzNEY4NkYyMTFFNjhGQUNCRjFGQUY4Qzg0OUMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4xWXTvAAAAfUlEQVR42mJgwA64gHgDEOswYZHkBeIdQPweiK+jSwoC8QkgngbEjOiSIkB8Doh7sElKAvFVIG7B5iB5IL4JxJU4HMxwDIg3MuABIBNuAXEFPkUgN1wD4iZ8ikShvujG5gvkcDgJxFPxKeID4sNAPA+ImRnwxAXIZ9oAAQYAUZESxpZKnHcAAAAASUVORK5CYII=');
    }
  }
}