.titles-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.titles-list__item {
  font-weight: 300;
  text-transform: uppercase;
  color: transparentize($white, 0.4);
  padding: 20px;
  cursor: pointer;
  position: relative;
  &:hover {
    color: $white;
  }
  &:not(.is-active)::before {
    content: '';
    background: transparentize($white, 0.4);
    position: absolute;
    height: 100%;
    width: 1px;
    left: 0;
    top: 0;
  }
  &.is-active {
    padding: 0 0 0 20px;
    margin-left: -20px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAANCAYAAACUwi84AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjlFRjE4NTU2ODQ5NjExRTZCQ0M5Qjc4QUI1RkUwN0Y4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjlFRjE4NTU3ODQ5NjExRTZCQ0M5Qjc4QUI1RkUwN0Y4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OUVGMTg1NTQ4NDk2MTFFNkJDQzlCNzhBQjVGRTA3RjgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OUVGMTg1NTU4NDk2MTFFNkJDQzlCNzhBQjVGRTA3RjgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6EeMiTAAAAh0lEQVR42mL4//8/AxAbArEwlI2CQYQqEP8D4rtALINNAQcQn/gPAbeBWBpdAQjzA/FpqKIbQCyJrgCEBYH4HFTRNSAWQ1cAwiJAfBGq6DKIz4DF5ZpA/BeqqABdEt0aUWRJrA5FljyJzasggh2Ij0IlMQILOSQfALE8tpAEYTOYv9ExQIABAMY0VIOKIW0TAAAAAElFTkSuQmCC') no-repeat 0 25px;
  }
}

.titles-list__name {
  display: inline-block;
  text-align: left;
  font-size: 24px;
  line-height: 1.3;

  .titles-list__item.is-active & {
    font-size: 40px;
    font-weight: 500;
    color: $white;
    padding-right: 70px;
  }
}

.titles-list__link {
  display: none;
  text-transform: none;
  font-size: 14px;
  text-align: right;
  font-weight: 300;
  font-style: italic;
  cursor: pointer;
  margin-right: -70px;
  .titles-list__item.is-active & {
    display: block;
    color: transparentize($white, 0.4);
  }
}