

.filter__title {
  text-transform: uppercase;
  @include responsive-font(0.8vw, 12px, 15px);
  font-weight: 600;
}

.filter__container--range {
  .filter {
    margin: 15px 0;
  }
}