.anm-tabs__title {
  color: map-get($foundation-palette, secondary);
  font-family: $font-condensed;

  &.is-active {
    color: map-get($foundation-palette, alert);
    a {
      text-decoration: underline;
    }
  }
  a {
    color: inherit;
  }
}