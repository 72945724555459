@charset "utf-8";

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'jquery.mCustomScrollbar';
@import "susy";

@include foundation-global-styles;
//@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-flex-video;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
//@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
//@include foundation-visibility-classes;
//@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;


@import 'helpers/mixins';
@import 'vendor/flexslider';
@import 'vendor/ion.rangeSlider';
//@import 'vendor/ion.rangeSlider.skinHTML5';@import 'general/animations/_animations.scss';
@import 'general/general/_general.scss';
@import 'general/grid/_grid.scss';
@import 'general/page-background/_page-background.scss';
@import 'general/typography/bebas/_bebas.scss';
@import 'general/typography/futura/_futura.scss';
@import 'sprite/_sprite.scss';
@import 'layout/_filters.scss';
@import 'layout/_general.scss';
@import 'layout/_main-wrap.scss';
@import 'layout/_main.scss';
@import 'layout/_page-content.scss';
@import 'layout/_sidebar.scss';
@import 'components/address/_address.scss';
@import 'components/anm-tabs/_anm-tabs.scss';
@import 'components/back-buttons/_back-buttons.scss';
@import 'components/bc-select/_bc-select.scss';
@import 'components/brand/_brand.scss';
@import 'components/buttons/btn/_btn.scss';
@import 'components/buttons/close-btn/_close-btn.scss';
@import 'components/catalog-filter/_catalog-filter.scss';
@import 'components/catalog-table/_catalog-table.scss';
@import 'components/checkbox-custom/_checkbox-custom.scss';
@import 'components/content-bar/_content-bar.scss';
@import 'components/content-wysiwyg/_content-wysiwyg.scss';
@import 'components/custom-scroller/_custom-scroller.scss';
@import 'components/easypin-marker/_easypin-marker.scss';
@import 'components/easypin/_easypin.scss';
@import 'components/filter/_filter.scss';
@import 'components/flexslider/_content-flexslider.scss';
@import 'components/flexslider/_flexslider.scss';
@import 'components/form/_form.scss';
@import 'components/full-slider/_full-slider.scss';
@import 'components/google-maps/_google-maps.scss';
@import 'components/header/_header.scss';
@import 'components/icon-nav/_icon-nav.scss';
@import 'components/icons/_icon-nav.scss';
@import 'components/icons/_img-icon.scss';
@import 'components/irs/_irs.scss';
@import 'components/lightbox/_fancybox.scss';
@import 'components/nav/_nav-submenu.scss';
@import 'components/nav/_nav.scss';
@import 'components/navigation/_navigation.scss';
@import 'components/office-preview-gallery/_office-preview-gallery.scss';
@import 'components/order-block/_order-block.scss';
@import 'components/page-title/_page-title.scss';
@import 'components/phone-alert/_phone-alert.scss';
@import 'components/phone/_phone.scss';
@import 'components/range-slider/_range-slider.scss';
@import 'components/rent-price/_rent-price.scss';
@import 'components/rhombus-photo/_rhombus-photo.scss';
@import 'components/side-nav/_side-nav.scss';
@import 'components/sidebar/_sidebar.scss';
@import 'components/team-member/_team-member.scss';
@import 'components/titles-list/_titles-list.scss';
/* No files to import found in partials//**//*.scss */@import 'pages/contacts/_contacts.scss';
@import 'pages/home/home-extra-link/_home-extra-link.scss';
@import 'pages/home/home-footer/_home-footer.scss';
@import 'pages/home/motto/_motto.scss';
@import 'pages/rent/_rent.scss';
@import 'pages/rent/characteristics/_characteristics.scss';
@import 'pages/rent/contact-data/_contact-data.scss';
@import 'pages/whyUs/advantages/_advantages-list.scss';
