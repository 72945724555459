.bc-select {
  appearance: none;
  border: 1px solid map-get($foundation-palette, alert);
  color: map-get($foundation-palette, alert);
  font-family: $font-condensed;
  line-height: 1;
  padding: 7px 10px;
  @include disable-mouse-outline;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAECAYAAABCxiV9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjU5QjVENDE4ODk3MzExRTY5NkJGRjFFOUU1MDBFMEE0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjU5QjVENDE5ODk3MzExRTY5NkJGRjFFOUU1MDBFMEE0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NTlCNUQ0MTY4OTczMTFFNjk2QkZGMUU5RTUwMEUwQTQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NTlCNUQ0MTc4OTczMTFFNjk2QkZGMUU5RTUwMEUwQTQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5hN39RAAAAS0lEQVR42mL8n8ZwjoGBoRSI9zIggBMQ9zIBiTwgXgrEgVAJfyBeDsT5LEDiCBB7AvEWIPYAYl8ofZ4Jqvo8EDsCsSaUBvEZAAIMADe0DL0d6eDAAAAAAElFTkSuQmCC') no-repeat right center;
  background-origin: content-box;
  width: auto;
}