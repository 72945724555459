.content-bar__main {
  padding: $content-space;
  align-self: flex-start;
  max-width: $sidebar-width; //hello, ie!

}

.content-bar__title {
  font-family: $font-condensed;
  font-size: 35px;
  text-transform: uppercase;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid transparentize($black, 0.8);
}

.content-bar__content {
  max-height: 350px;
  .mCSB_inside > .mCSB_container {
    padding: 0;
  }
  .mCustomScrollBox > .mCSB_scrollTools {
    right: 0;
    left: auto;
  }
}