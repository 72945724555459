.characteristics {

}

.characteristics__title {
  font-family: $font-condensed;
  color: map-get($foundation-palette, alert);
}

.characteristics__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  width: span(7);
}

.characteristics__item {
  font-weight: 500;
  font-size: 15px;
  flex: 1 0 span(6);
  margin-bottom: 10px;
  &:nth-child(2n+1) {
    margin-right: gutter();
  }
}

.characteristics__item-title {
  color: $dark-gray;
  font-size: inherit;
}