.advantages-list {
  list-style: none;
}

.advantages-list__item {
  width: 150px;
  margin: 40px;
  float: left;
  &:nth-child(2n +1) {
    clear: left;
  }
}