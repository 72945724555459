.main-wrap {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  &.-vertical-justify {
    justify-content: space-between;
  }
}

