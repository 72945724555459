.img-icon {
  text-align: center;
}

.img-icon__image {
  display: inline-block;
}

.img-icon__title {
  display: block;
  font-family: $font-condensed;
  color: $white;
}
