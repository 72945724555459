.easypin-marker {
  z-index: 200;
  &:before {
    content: '';
    display: block;
    width: 36px;
    height: 36px;
    background: transparentize($black, 0.3);
    transform: rotate(45deg);
    border: 1px solid transparentize($white, 0.3);
    position: relative;
    padding: 7px;
  }
  &::after {
    content: '+';
    font-size: 80px;
    font-family: $font-condensed;
    color: $white;
    font-weight: 100;
    line-height: 0;
    position: absolute;
    top: 18px;
    left: 3px;
  }
  &.is-active {
    &::after {
      left: 2px;
      transform: rotate(45deg);
    }
  }
}

.easypin-marker__text {
  color: transparentize($white, 0.2);
  font-family: $font-condensed;
  position: absolute;
  white-space: nowrap;
  top: 6px;
  left: 56px;
}