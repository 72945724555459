//расположение спрайта с икноками
.custom-scroller {
  .mCSB_scrollTools {
    .mCSB_buttonUp,
    .mCSB_buttonDown,
    .mCSB_buttonLeft,
    .mCSB_buttonRight {
      background-image: url('#{$image-path}/mCSB_buttons.png'); /* css sprites */
    }
  }
}

//изменение позиции скролла
.custom-scroller--right {
  .mCustomScrollBox>.mCSB_scrollTools {
    left: 0;
  }
}


//отступы внутри контейнера со скроллом
/*.custom-scroller {
  .mCSB_inside > .mCSB_container {
    padding: 0 40px;
  }
}*/
