.team-member {
  bottom: 100% !important;
  left: -47px;
  margin: 0 0 8px;
  padding: 0;
}

.team-member__photo {
  position: absolute;
  top: 27px;
}

.team-member__descr {
  margin-left: 65px;
  padding: 25px 25px 25px 115px;
  background-color: transparentize($black, 0.25);
  height: 184px;
  min-width: 300px;
  color: $white;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.team-member__name {
  font-size: 15px;
  line-height: 1.1;
  font-weight: 300;
  color: transparentize($white, 0.3);
}

.team-member__surname {
  text-transform: uppercase;
  font-weight: 600;
  color: $white;
  display: block;
  text-align: right;
}

.team-member__position {
  font-family: $font-condensed;
  text-transform: uppercase;
  color: transparentize($white, 0.3);
  line-height: 1.1;
}
.team-member__contacts {
  font-family: $font-condensed;
  line-height: 1.1;
  > a {
    display: block;
    text-align: right;
  }
}