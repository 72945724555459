//Light
@font-face {
  font-family: 'Futura';
  src: url('#{$font-path}/futura/futuralightc-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

//Light Italic
@font-face {
  font-family: 'Futura';
  src: url('#{$font-path}/futura/FuturaLightC-Italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

//Regular
@font-face {
  font-family: 'Futura';
  src: url('#{$font-path}/futura/futurabookc-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

//Regular Italic
@font-face {
  font-family: 'Futura';
  src: url('#{$font-path}/futura/futurabookc-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

//Medium
@font-face {
  font-family: 'Futura';
  src: url('#{$font-path}/futura/futuramediumc-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

//Medium Italic
@font-face {
  font-family: 'Futura';
  src: url('#{$font-path}/futura/futuramediumc-italic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

//Demi-bold
@font-face {
  font-family: 'Futura';
  src: url('#{$font-path}/futura/futurademic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

//Demi-bold Italic
@font-face {
  font-family: 'Futura';
  src: url('#{$font-path}/futura/futurademic-italic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}