.rhombus-photo {
  width:130px;
  height:130px;
  overflow:hidden;
  transform: rotate(45deg);
  position:relative;
}
.rhombus-photo > img {
  transform: rotate(-45deg);
  position: absolute;
  top: -52px;
  left: 27px;
  width: 150%;
  max-width: 200%;
}