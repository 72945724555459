.main {
  margin: $content-space;
  &.-flex {
    display: flex;
    height: 100%;
    justify-content: flex-end;
  }
  &.-center-justify {
    justify-content: center;
  }
}