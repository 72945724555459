.sidebar,
.content-bar {
  //flex: 0 0 $sidebar-width;
  flex: 0 0 auto;
  &.-right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    padding-bottom: 25px;
  }
  &.-overlayed {
    position: absolute;
    width: 385px;
    height: 100%;
    z-index: 1;
    &.-left {
      min-width: $sidebar-width;
      width: auto;
      left: 0;
    }
  }
  &.-left {
    justify-content: center;
    align-items: flex-start;
    order: -1;
  }
  &.-dark {
    background-color: transparentize($black, 0.3);
    color: $white;
  }
  &.-vert-justify {
    justify-content: space-between;
  }
  &.-top-space {
    padding-top: $content-space;
  }
  &.-flexed {
    display: flex;
    flex-direction: column;
  }
  &.-with-width {
    flex-basis: $sidebar-width-narrow;
  }
}
.sidebar {
  &.-right {
    padding-left: 25px;
  }
}

.sidebar-footer {
  padding-left: 25px;
  padding-right: 25px;
  color: $white;
  &.-dark {
    color: $black;
  }
  &.-inline {
    border-top: 1px solid transparentize($black, 0.8);
    padding-top: 10px;
    .sidebar-contacts {
      display: inline-block;
      vertical-align: baseline;
      padding-right: 25px;
    }
  }
}

.sidebar-contacts__address {
  margin-top: 25px;
}

.sidebar-contacts__phone {
  margin-top: 25px;
}