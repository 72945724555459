.icon-about {
  &::before {
    content: '';
    @include sprite($about-company);
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(50%);
    transition: all ease 0.3s;
  }
}

.icon-gallery {
  &::before {
    content: '';
    @include sprite($gallery);
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(50%);
    transition: all ease 0.3s;
  }
}

.icon-contacts {
  &::before {
    content: '';
    @include sprite($contacts);
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(50%);
    transition: all ease 0.3s;
  }
}