.phone-alert {
  margin: 40px 25px 25px;
  float: right;
  width: 60px;
  padding: 18px;
  background-color: map-get($foundation-palette, danger);
  border-radius: 50%;
  box-shadow: 0 0 5px 25px transparentize(map-get($foundation-palette, danger), 0.85);
  &::after {
    content: '';
    @include sprite($phone-receiver);
    display: block;
  }
  &:hover {
    animation: pulse 2s infinite;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 5px 14px transparentize(map-get($foundation-palette, danger), 0.85);
  }
  50% {
    box-shadow: 0 0 5px 25px transparentize(map-get($foundation-palette, danger), 0.75);
  }
  100% {
    box-shadow: 0 0 5px 14px transparentize(map-get($foundation-palette, danger), 0.85);
  }
}