.rent-short {
  margin-bottom: $content-space;
  width: $sidebar-width-narrow;
  z-index: 1;
  &.-wider {
    width: $sidebar-width;
  }

}

.rent-short__header {
  border-bottom: 1px solid transparentize($white, 0.6);
  padding-bottom: $content-space;
  margin-bottom: $content-space;
  @include clearfix;
  &.-no-bottom-space {
    margin-bottom: 0;
  }
}

.rent-short__title {
  line-height: 1;
  text-transform: uppercase;
  font-family: $font-condensed;
  font-size: 30px;
  padding-left: $content-space / 2;
  float: left;
}

.rent-short__header-btn {
  float: right;
}

.rent__prices {
  padding: 0 $content-space $content-space;
}

.rent__footer {
  margin-bottom: $content-space * 2;
  z-index: 1;
}

.rent-main {
  padding: $content-space 35px;
}

.bc-short-icons {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid $light-gray;
  flex: 1 0 span(9);
}

.bc-short-icons__item {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  flex: 0 1 span(4);
  margin-right: gutter();
  padding-bottom: 12px;
  &:nth-child(3n) {
    margin-right: 0;
  }
}

.bc-short-icons__img {
  margin-right: 10px;
  float: left;
}

.office__header {
  display: flex;
  margin-bottom: 50px;
  &.-small-offset {
    margin-bottom: 25px;
  }
}

.bc-select__wrap {
  flex: 0 1 span(3);
  margin-right: gutter();
}

.office__content {
  display: flex;
  .l-col {
    flex: 1 0 span(7);
    margin-right: gutter();
    max-width: 600px;
  }
  .r-col {
    flex: 1 0 span(5);
  }
}

.office__details {
  margin-top: 50px;
}