/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$about-company-name: 'about-company';
$about-company-x: 18px;
$about-company-y: 24px;
$about-company-offset-x: -18px;
$about-company-offset-y: -24px;
$about-company-width: 18px;
$about-company-height: 19px;
$about-company-total-width: 67px;
$about-company-total-height: 46px;
$about-company-image: 'sprite.png';
$about-company: (18px, 24px, -18px, -24px, 18px, 19px, 67px, 46px, 'sprite.png', 'about-company', );
$contacts-name: 'contacts';
$contacts-x: 0px;
$contacts-y: 24px;
$contacts-offset-x: 0px;
$contacts-offset-y: -24px;
$contacts-width: 18px;
$contacts-height: 22px;
$contacts-total-width: 67px;
$contacts-total-height: 46px;
$contacts-image: 'sprite.png';
$contacts: (0px, 24px, 0px, -24px, 18px, 22px, 67px, 46px, 'sprite.png', 'contacts', );
$eye-light-name: 'eye-light';
$eye-light-x: 0px;
$eye-light-y: 0px;
$eye-light-offset-x: 0px;
$eye-light-offset-y: 0px;
$eye-light-width: 25px;
$eye-light-height: 24px;
$eye-light-total-width: 67px;
$eye-light-total-height: 46px;
$eye-light-image: 'sprite.png';
$eye-light: (0px, 0px, 0px, 0px, 25px, 24px, 67px, 46px, 'sprite.png', 'eye-light', );
$gallery-name: 'gallery';
$gallery-x: 49px;
$gallery-y: 0px;
$gallery-offset-x: -49px;
$gallery-offset-y: 0px;
$gallery-width: 18px;
$gallery-height: 19px;
$gallery-total-width: 67px;
$gallery-total-height: 46px;
$gallery-image: 'sprite.png';
$gallery: (49px, 0px, -49px, 0px, 18px, 19px, 67px, 46px, 'sprite.png', 'gallery', );
$phone-light-name: 'phone-light';
$phone-light-x: 49px;
$phone-light-y: 19px;
$phone-light-offset-x: -49px;
$phone-light-offset-y: -19px;
$phone-light-width: 13px;
$phone-light-height: 24px;
$phone-light-total-width: 67px;
$phone-light-total-height: 46px;
$phone-light-image: 'sprite.png';
$phone-light: (49px, 19px, -49px, -19px, 13px, 24px, 67px, 46px, 'sprite.png', 'phone-light', );
$phone-receiver-name: 'phone-receiver';
$phone-receiver-x: 25px;
$phone-receiver-y: 0px;
$phone-receiver-offset-x: -25px;
$phone-receiver-offset-y: 0px;
$phone-receiver-width: 24px;
$phone-receiver-height: 24px;
$phone-receiver-total-width: 67px;
$phone-receiver-total-height: 46px;
$phone-receiver-image: 'sprite.png';
$phone-receiver: (25px, 0px, -25px, 0px, 24px, 24px, 67px, 46px, 'sprite.png', 'phone-receiver', );
$spritesheet-width: 67px;
$spritesheet-height: 46px;
$spritesheet-image: 'sprite.png';
$spritesheet-sprites: ($about-company, $contacts, $eye-light, $gallery, $phone-light, $phone-receiver, );
$spritesheet: (67px, 46px, 'sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
