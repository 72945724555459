.catalog-table {
  @include responsive-font(0.8vw, 12px, 15px);
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  vertical-align: top;
  text-align: center;
  thead > tr {
    border: 0 solid $medium-gray;
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  tbody {
    > tr {
      &:hover {
        background-color: transparentize(map-get($foundation-palette, bright), 0.8);
      }
    }
  }
  th {
    padding: 12px 10px;
  }
  td {
    padding: 10px;
  }
}