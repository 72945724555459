.btn {
  @include button-base;
  //other types of button
  //with arrow pointer
  &.-pointing {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKCAYAAACXDi8zAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkE1Q0E0QTIwN0ZGNDExRTZBOTU2Q0MzODFGQzU3QkI4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkE1Q0E0QTIxN0ZGNDExRTZBOTU2Q0MzODFGQzU3QkI4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QTVDQTRBMUU3RkY0MTFFNkE5NTZDQzM4MUZDNTdCQjgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QTVDQTRBMUY3RkY0MTFFNkE5NTZDQzM4MUZDNTdCQjgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5/nAB9AAAAXUlEQVR42mL4//9/DhBXAzEDMgYR4kB8DYhr0CWwSiJrB0leBeJaEJ+FAQFeArETEO8DYkYmBlTwD4iB5jAwIxslCsRXgLgB2Q4xqGA9suUYgjCJXCCuQ/cgQIABADrFnankpz31AAAAAElFTkSuQmCC') no-repeat calc(100% - 1em) center;
    padding-right: 3em;
  }
  &.-large {
    padding: 0.9em 2.55em;
  }
  &.-right-align {
    align-self: flex-start;
  }
  &.-condensed {
    font-family: $font-condensed;
    font-size: 17px;
  }
  &.-hollow {
    border: 1px solid;
  }
  &.-font-normal {
    font-family: $body-font-family;
  }
  &.-uppercase {
    text-transform: uppercase;
  }
  &.-size-small {
    padding-left: 7px;
    padding-right: 7px;
    font-size: 12px;
  }
}

.btn-icon {
  width: 60px;
  height: 60px;
  padding: 10px;
}

//colors
.btn,
.btn-icon {
  &.-primary {
    @include button-style(map-get($foundation-palette, primary), $white, 0.2);
  }
  &.-gentle {
    @include button-style($white, $white, 0.7);
  }
  &.-gentle-hollow {
    @include button-hollow-style($white, transparentize($white, 0.1), $white, 0.7);
  }
  &.-back {
    margin-bottom: 15px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAKCAYAAAB4zEQNAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjY1OUNCMTM5ODk2MjExRTZBQTI0RDk5QTk1MzlGNUEzIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjY1OUNCMTNBODk2MjExRTZBQTI0RDk5QTk1MzlGNUEzIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NjU5Q0IxMzc4OTYyMTFFNkFBMjREOTlBOTUzOUY1QTMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NjU5Q0IxMzg4OTYyMTFFNkFBMjREOTlBOTUzOUY1QTMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7yKN5GAAAAZklEQVR42mL4//8/AxoWB+IEIGZmYkAFckAcC8RngPgvsg51IC4FYiWYGEzCAIiLgVga2QoQYQ/EBUAsgm4/yM7/DLgAVJU+EJdgMxaG1aAOUsYmCcKyUBN0sUmCsBgQx4MCASDAAHvBsAsbGXznAAAAAElFTkSuQmCC') no-repeat 16px center;
  }
  &.-dark-hollow {
    @include button-hollow-style($black, transparentize($black, 0.1), $black, 0.7);
  }
  &.-bright {
    @include button-style(map-get($foundation-palette, bright), $white, 0.2);
  }
}

