.home-footer {
  font-size: 0;
  margin: $content-space;
}

.home-footer__logo,
.home-footer__link {
  display: inline-block;
  vertical-align: bottom;
}

.home-footer__logo {
  position: relative;
  margin-bottom: 15px;
  &::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 47px;
    transform: skewY(-29deg);
    right: 0;
    bottom: -7px;
    background-color: transparentize($white, 0.7);
    z-index: 1;
  }
}

.home-footer__link {
  font-size: 15px;
  color: $white;
  font-weight: 300;
  margin-left: -25px;
  position: relative;
  z-index: 2;
  > a {
    color: inherit;
    display: block;
    padding: 0 15px;
    line-height: 47px;
    background-color: hsla(30, 10%, 26%, 0.85);
    &:hover {
      background-color: hsla(30, 10%, 26%, 0.95);
    }
  }
}