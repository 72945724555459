/*
.nav-submenu {


}*/

.nav-submenu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  min-width: 100%;
  z-index: 200;
  .nav__item:hover & {
    display: block;
  }
}

.nav-submenu__item {

  transition: all ease 0.2s;
  &:hover {
    background-color: transparentize($white, 0.7);
  }
}

.nav-submenu__item > a {
  display: block;
  padding: 8px 15px;
}
