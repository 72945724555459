.contact-data {

}

.contact-data__title {
  font-weight: 500;
  font-size: 19px;
  color: $dark-gray;
}

.contact-data__phone {
  font-weight: 500;
  font-size: 24px;
  color: map-get($foundation-palette, alert);
}