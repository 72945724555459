/* Ion.RangeSlider, Simple Skin
// css version 2.0.3
// © Denis Ineshin, 2014    https://github.com/IonDen
// © guybowden, 2014        https://github.com/guybowden
// ===================================================================================================================*/

/* =====================================================================================================================
// Skin details */

.irs {
  height: 19px;
}
.irs-with-grid {
  height: 19px;
}
.irs-line {
  height: 2px;
  top: 11px;
  background: $light-gray;
}
.irs-line-left {
  height: 2px;
}
.irs-line-mid {
  height: 2px;
}
.irs-line-right {
  height: 2px;
}

.irs-bar {
  height: 2px;
  top: 11px;
  background: $black;
}
.irs-bar-edge {
  height: 2px;
  top: 11px;
  width: 14px;
  background: $black;
}

.irs-shadow {
  height: 2px; top: 38px;
}

.irs-slider {
  top: 6px;
  width: 11px;
  height: 11px;
  border-radius: 6px;
  border: 2px solid $black;
  background: $white;
  cursor: pointer;
}

.irs-slider.state_hover, .irs-slider:hover {
  background: $light-gray;
}

.irs-min, .irs-max {
  color: #333;
  font-size: 12px; line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 5px;
  background: rgba(0,0,0,0.1);
  display: none;
}

.lt-ie9 .irs-min, .lt-ie9 .irs-max {
  background: #ccc;
}

.irs-from, .irs-to, .irs-single {
  color: #fff;
  font-size: 14px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  border-radius: 3px;
  display: none;
}
.lt-ie9 .irs-from, .lt-ie9 .irs-to, .lt-ie9 .irs-single {
  background: #999;
}

.irs-grid {
  height: 27px;
}
.irs-grid-pol {
  opacity: 0.5;
  background: #428bca;
}
.irs-grid-pol.small {
  background: #999;
}

.irs-grid-text {
  bottom: 5px;
  color: #99a4ac;
}

.irs-disabled {
}
