.form {
  color: $black;
  font-size: 20px;
  font-weight: 500;
  line-height: 2;
}

.form__row {
  &.-narrow {
    width: 80%;
  }
}

.form__input {
  @include foundation-form-text;
  box-shadow: none;
  border: 0 solid $light-gray;
  border-bottom-width: 1px;
  &:focus,
  &:active {
    border: 0 solid $medium-gray;
    border-bottom-width: 1px;
  }
}

.form__text-hightlight {
  color:map-get($foundation-palette, alert);
}
