.rent-price {
  margin-bottom: 1em;
  font-weight:500;
  line-height: 1;
  &.-large {
    font-size: 35px;
  }
  &.-medium {
    font-size: 28px;
  }
  &.-small {
    font-size: 25px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.rent-price__title {
  font-size: 14px;
  color: $dark-gray;
  margin-bottom: 7px;
  display: block;
}