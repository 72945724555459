.content-wysiwyg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase;
    font-size: 16px;
    margin: 15px 0;
    color: $black;
    font-weight: 500;
  }
  p {
    color: $dark-gray;
  }
}

.content-bar--dark {
  .content-wysiwyg {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }
    p {
      color: $white;
    }
  }
}
