.easypin {
  position: absolute !important;
/*  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);*/
  top: 0;
  min-height: 100%;
  min-width: 100%;
  border: 0 !important;
  background: url('#{$image-path}/uploads/backgrounds/team.jpg') no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  .pin {
    opacity: 0 !important;
  }
}
