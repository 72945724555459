.office-preview-gallery {
  display: flex;
  flex-flow: row wrap;
}

.office-preview-gallery__item {
  flex-basis: 50%;
  position: relative;
  transition: all ease 0.5s;
  &::after {
    content: '';
    background-color: transparentize($black, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease 0.5s;
  }
  &:hover {
    &::after {
      content: none;
    }
  }
}