.content-flexslider {
  max-width: $sidebar-width;
  background: transparent;
  .flex-control-nav {
    display: none;
  }
  .flex-direction-nav {
    top: 41px;
    right: 30px;
    left: auto;
  }
}