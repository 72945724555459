.catalog-filter {
  display: flex;
  margin-left: span(3) + gutter();
  flex: 1 0 span(9);
}

.filters {
  display: flex;
  min-width: 100%;
}

.filter {
  display: flex;
}

.filter__title {
  margin-bottom: 0;
  margin-right: 15px;
}

.filter__content {
  display: flex;
}

.filter__container {
  margin-right: span(1);
}

.filter__container--checkbox {
  flex: 1 0 span(2);
  .filter__content {

    flex-wrap: wrap;
    justify-content: space-between;
    width: 77px; // размер двух кастомных чекбоксов с отступами
  }
}

.filter__container--range {
  flex: 1 0 span(6);

  .filter__content {
    flex: 1 0 span(5);
  }
}

.range-slider {
  min-width: 100%;
}

.range-slider__data {
  flex: 0 0 60px;
}

.range-slider__slider {
  flex: 1 0 100px;
  margin-right: 12px;
}
